import { Contract, ethers } from "ethers";
import ERC20 from "../abis/ERC20.json";
import FACTORY from "../abis/IUniswapV2Factory.json";
import ROUTER from "../abis/UniswapV2Router02.json";
import PAIR from "../abis/IUniswapV2Pair.json";
import LaunchpadABI from "../abis/LaunchpadABI.json";
import PresaleABI from "../abis/Launchpad_PresaleABI.json";
import StakingABI from "../abis/Launchpad_StakingABI.json";
import { formatEtherValueFromWei, formatEtherValue, formatEtherValueFromWeiWithDecimal } from "./commonFunction";

export function getWeth(address, signer) {
  return new Contract(address, ERC20.abi, signer);
}

export function getRouter(address, signer) {
  try {
    return new Contract(address, ROUTER.abi, signer);
  } catch {
    return false;
  }
}

export function getFactory(address, signer) {
  return new Contract(address, FACTORY.abi, signer);
}

export function getLaunchPadFactory(address, signer) {
  return new Contract(address, LaunchpadABI, signer);
}
export function getStakingFactory(address, signer) {
  return new Contract(address, StakingABI, signer);
}
export function getPresaleFactory(address, signer) {
  return new Contract(address, PresaleABI, signer);
}

export async function checkForPair(weth, factory, tokenAddress) {
  try {
    const pairAddress = await factory.getPair(weth, tokenAddress);
    if (pairAddress !== "0x0000000000000000000000000000000000000000") {
      return pairAddress;
    } else {
      return false;
    }
  } catch {
    return false;
  }
}

export async function checkOrGetToken(address, signer) {
  try {
    const contract = new Contract(address, ERC20.abi, signer);
    if (contract) {
      return {
        symbol: await contract.symbol(),
        name: await contract.name(),
        address: address.toLowerCase(),
        decimals: await contract.decimals(),
        totalSupply: formatEtherValueFromWeiWithDecimal(ethers.BigNumber.from(await contract.totalSupply()), await contract.decimals()),
      };
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
}

export async function getTokenBalanceOnly(address, tokenAddress, signer, wethAddress, provider) {
  if (tokenAddress?.toLowerCase() === wethAddress?.toLowerCase()) {
    const balWei = await provider.getBalance(address);
    return await formatEtherValueFromWei(balWei);
  } else {
    const contract = await getWeth(tokenAddress, signer);
    const balWei = await contract.balanceOf(address);
    const decimals = await contract.decimals();
    return await formatEtherValueFromWeiWithDecimal(balWei, decimals);
  }
}

export async function getDecimals(token) {
  const decimals = await token
    .decimals()
    .then((result) => {
      return result;
    })
    .catch((error) => {
      // console.log("No tokenDecimals function for this token, set to 0");
      return 0;
    });
  return decimals;
}

export async function getBalanceAndSymbol(accountAddress, address, provider, signer, weth_address, coins, routerContract) {
  try {
    let wthAddress = await weth_address;
    if (address?.toLowerCase() === wthAddress?.toLowerCase()) {
      const balanceRaw = await provider.getBalance(accountAddress);
      return {
        balance: formatEtherValueFromWei(balanceRaw),
        symbol: coins[0].symbol,
      };
    } else {
      const token = new Contract(address, ERC20.abi, signer);
      const balanceRaw = await token.balanceOf(accountAddress);
      const allowance = await token.allowance(accountAddress, routerContract.address);
      const symbol = await token.symbol();
      const decimals = await token.decimals();
      return {
        balance: formatEtherValueFromWeiWithDecimal(balanceRaw, decimals),
        symbol: symbol,
        allowance: formatEtherValueFromWeiWithDecimal(allowance, decimals),
        decimals: decimals,
      };
    }
  } catch (error) {
    // console.log(error);
    return false;
  }
}

export async function fetchReserves(address1, address2, pair, signer) {
  try {
    // Get decimals for each coin
    // const coin1 = new Contract(address1, ERC20.abi, signer);
    // const coin2 = new Contract(address2, ERC20.abi, signer);

    // const coin1Decimals = await getDecimals(coin1);
    // const coin2Decimals = await getDecimals(coin2);

    // Get reserves
    const reservesRaw = await pair.getReserves();

    // Put the results in the right order
    const results = [
      (await pair.token0()).toLowerCase() === address1.toLowerCase() ? reservesRaw[0] : reservesRaw[1],
      (await pair.token1()).toLowerCase() === address2.toLowerCase() ? reservesRaw[1] : reservesRaw[0],
    ];
    return [results[0], results[1]];
  } catch (err) {
    // console.log("error in fetchReserves : ", err);
    return [0, 0];
  }
}

export async function getReserves(address1, address2, factory, signer, accountAddress) {
  try {
    const pairAddress = await factory.getPair(address1, address2);
    const pair = new Contract(pairAddress, PAIR.abi, signer);

    const token1 = new Contract(address1, ERC20.abi, signer);
    const token1Decimals = await getDecimals(token1);

    const token2 = new Contract(address2, ERC20.abi, signer);
    const token2Decimals = await getDecimals(token2);

    if (pairAddress !== "0x0000000000000000000000000000000000000000") {
      const reservesRaw = await fetchReserves(address1, address2, pair, signer);
      const liquidityTokens_BN = await pair.balanceOf(accountAddress);
      const pairDecimals = await pair.decimals();

      return [
        formatEtherValueFromWeiWithDecimal(reservesRaw[0], token1Decimals),
        formatEtherValueFromWeiWithDecimal(reservesRaw[1], token2Decimals),
        formatEtherValueFromWeiWithDecimal(liquidityTokens_BN, pairDecimals),
      ];
    } else {
      return [0, 0, 0];
    }
  } catch (err) {
    // console.log("error in getReserves : ", err);
    return [0, 0, 0];
  }
}

export async function getReservesNonFixed(address1, address2, factory, signer, accountAddress) {
  try {
    const pairAddress = await factory.getPair(address1, address2);
    const pair = new Contract(pairAddress, PAIR.abi, signer);

    const token1 = new Contract(address1, ERC20.abi, signer);
    const token1Decimals = await getDecimals(token1);

    const token2 = new Contract(address2, ERC20.abi, signer);
    const token2Decimals = await getDecimals(token2);

    if (pairAddress !== "0x0000000000000000000000000000000000000000") {
      const reservesRaw = await fetchReserves(address1, address2, pair, signer);

      return [ethers.utils.formatUnits(reservesRaw[0], token1Decimals), ethers.utils.formatUnits(reservesRaw[1], token2Decimals)];
    } else {
      return [0, 0, 0];
    }
  } catch (err) {
    // console.log("error in getReserves : ", err);
    return [0, 0, 0];
  }
}

export async function getAmountOut(address1, address2, amountIn, routerContract, factory, signer, slippage) {
  try {
    const token1 = new Contract(address1, ERC20.abi, signer);
    const token1Decimals = await getDecimals(token1);

    const token2 = new Contract(address2, ERC20.abi, signer);
    const token2Decimals = await getDecimals(token2);

    const pairAddress = await factory.getPair(address1, address2);
    if (pairAddress !== "0x0000000000000000000000000000000000000000") {
      const values_out = await routerContract.getAmountsOut(ethers.utils.parseUnits(String(amountIn), token1Decimals), [
        address1,
        address2,
      ]);

      const amountOutMinWei = values_out[1];
      const slippageAdjustedAmountOutMin = amountOutMinWei.sub(amountOutMinWei.mul(Number(slippage * 10000)).div(100 * 10000)).toString();

      const amount_out = ethers.utils.formatUnits(values_out[1], token2Decimals);

      const pair = new Contract(pairAddress, PAIR.abi, signer);
      const reserves = await fetchReserves(address1, address2, pair, signer);
      // const reservesEth0 = Number(ethers.utils.formatEther(reserves[0]));
      const reservesEth1 = Number(ethers.utils.formatUnits(reserves[1], token2Decimals));

      return {
        amountOut: Number(formatEtherValue(amount_out)),
        minamountOut: Number(formatEtherValueFromWeiWithDecimal(slippageAdjustedAmountOutMin, token2Decimals)),
        priceImpact: formatEtherValue((Number(amount_out) / reservesEth1) * 100),
        tradingFee: formatEtherValue((Number(amountIn) * 0.25) / 100),
        price: formatEtherValue(Number(amount_out) / Number(amountIn)),
        pairAddress: pairAddress,
      };
    } else {
      return {
        amountOut: "",
        minamountOut: "",
        priceImpact: "",
        tradingFee: "",
        price: "",
        pairAddress: null,
      };
    }
  } catch (err) {
    // console.log("error in getAmountOut : ", err);
    return {
      amountOut: "",
      minamountOut: "",
      priceImpact: "",
      tradingFee: "",
      price: "",
      pairAddress: null,
    };
  }
}

function delay(delay) {
  return new Promise((res) => setTimeout(res, delay));
}
export const checkHashStatusIsComplete = async (hash, provider, noOfTime = 25) => {
  for (let i = 0; i < noOfTime && i < noOfTime; i++) {
    try {
      const trx = await provider.getTransactionReceipt(hash);
      if (trx) {
        return { status: true };
      }
    } catch (err) {
      if (err.message === "Transaction not found") {
      } else {
        return { status: false, error: err.message };
      }
    }
    await delay(2000);
  }
};

export async function checkAllowance(address, accountAddress, routerContract, signer) {
  try {
    const token = new Contract(address, ERC20.abi, signer);
    const allowance = await token.allowance(accountAddress, routerContract?.address);
    const decimals = await token.decimals();
    return allowance ? ethers.utils.formatUnits(allowance, decimals) : 0;
  } catch {
    return false;
  }
}

export async function giveAllowance(address, routerContract, signer, value = false) {
  try {
    if (value) {
      const token = new Contract(address, ERC20.abi, signer);
      let decimal = await getDecimals(token, signer);
      const supply = await token.totalSupply();
      const tx = await token.approve(routerContract.address, ethers.utils.parseUnits(value.toString(), decimal));
      await tx.wait();
      return true;
    } else {
      const token = new Contract(address, ERC20.abi, signer);
      const supply = await token.totalSupply();
      const tx = await token.approve(routerContract.address, supply);
      await tx.wait();
      return true;
    }
  } catch (err) {
    // console.log("error in giveAllowance : ", err);
    return false;
  }
}

export async function swapTokens(address1, address2, amount, routerContract, accountAddress, signer, provider, slippage, deadlineTime) {
  try {
    const tokens = [address1, address2];
    const time = Math.floor(Date.now() / 1000) + Number(deadlineTime) + 1000;
    const deadline = ethers.BigNumber.from(time);

    const token1 = new Contract(address1, ERC20.abi, signer);
    const tokenDecimals = await getDecimals(token1);

    const token2 = new Contract(address2, ERC20.abi, signer);
    const token2Decimals = await getDecimals(token2);

    const amountIn = await ethers.utils.parseUnits(amount, tokenDecimals);
    const amountOut = await routerContract.getAmountsOut(amountIn, tokens);
    const amountOutMinWei = amountOut[1];
    const slippageAdjustedAmountOutMin = amountOutMinWei.sub(amountOutMinWei.mul(Number(slippage * 10000)).div(100 * 10000)).toString();

    const wethAddress = await routerContract.WETH();
    if (address1?.toLowerCase() === wethAddress?.toLowerCase()) {
      const tx = await routerContract.swapExactETHForTokens(slippageAdjustedAmountOutMin, tokens, accountAddress, deadline, {
        value: amountIn,
      });
      await tx.wait();
      return tx;
    } else if (address2?.toLowerCase() === wethAddress?.toLowerCase()) {
      // Token -> Eth
      const reciept = await routerContract.swapExactTokensForETH(amountIn, slippageAdjustedAmountOutMin, tokens, accountAddress, deadline);
      const tx = await checkHashStatusIsComplete(reciept?.hash, provider);
      await tx.wait();
      return tx;
    } else {
      const tx = await routerContract.swapExactTokensForTokens(amountIn, slippageAdjustedAmountOutMin, tokens, accountAddress, deadline);
      await tx.wait();
      return tx;
    }
  } catch (err) {
    // console.log("error in swapTokens : ", err);
    return false;
  }
}

export async function getPresaleRate(amount, tokenAdd, wethadd, usdtadd, factory, routerContract, signer, provider) {
  try {
    const token = new Contract(tokenAdd, ERC20.abi, signer);
    const decimal = await token.decimals();
    const amountOut = await routerContract.getAmountsOut(ethers.utils.parseUnits(amount.toString(), decimal), [wethadd, usdtadd]);
    return formatEtherValueFromWeiWithDecimal(amountOut[1], decimal);
  } catch (err) {
    // console.log("error : ", err);
    return "0";
  }
}

export async function getDilutedMarketCap(amount, tokenAdd, wethadd, usdtadd, factory, routerContract, signer, provider) {
  try {
    const token = new Contract(tokenAdd, ERC20.abi, signer);
    const decimal = await token.decimals();
    const amountOut = await routerContract.getAmountsOut(ethers.utils.parseUnits(amount.toString(), decimal), [wethadd, usdtadd]);
    return formatEtherValueFromWeiWithDecimal(amountOut[1], decimal);
  } catch (err) {
    // console.log("error : ", err);
    return "0";
  }
}

export async function addLiquidty(address1, amount1, amount2, account, slippage, deadlineTime, routerContract, signer) {
  try {
    if (routerContract) {
      const token1 = new Contract(address1, ERC20.abi, signer);
      const tokenDecimals = await getDecimals(token1);

      const time = Math.floor(Date.now() / 1000) + Number(deadlineTime) + 1000;
      const deadline = ethers.BigNumber.from(time);

      const amountIn = await ethers.utils.parseUnits(amount1.toString(), "18");

      await routerContract.addLiquidityETH(
        address1,
        ethers.utils.parseUnits(amount2.toString(), tokenDecimals),
        "0",
        "0",
        account,
        deadline.toString(),
        {
          value: amountIn,
        }
      );
      return true;
    } else {
      return false;
    }
  } catch (err) {
    // console.log("error in addLiquidity : ", err);
    return false;
  }
}

export async function calculateEstimateGasLiquidity(
  address1,
  amount1,
  amount2,
  account,
  slippage,
  deadlineTime,
  routerContract,
  signer,
  provider
) {
  try {
    const time = Math.floor(Date.now() / 1000) + Number(deadlineTime) + 1000;
    const deadline = ethers.BigNumber.from(time);
    const token1 = new Contract(address1, ERC20.abi, signer);
    const tokenDecimals = await getDecimals(token1);
    const amountIn = await ethers.utils.parseUnits(amount1?.toString(), "18");
    if (time && deadline && token1 && tokenDecimals && amountIn && routerContract) {
      const gasLimit = await routerContract?.estimateGas?.addLiquidityETH(
        address1,
        ethers.utils.parseUnits(amount2?.toString(), tokenDecimals),
        "0",
        "0",
        account,
        deadline?.toString(),
        {
          value: amountIn,
        }
      );
      const gasPrice = await provider.getGasPrice();
      const fee = gasLimit.mul(gasPrice);
      if (fee) {
        return ethers.utils.formatEther(fee);
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  } catch (err) {
    // console.log("error in calculateEstimateGasLiquidity : ", err);
    return 0;
  }
}
