import LaunchpadABI from "../abis/LaunchpadABI.json";
import LPStaking from "../abis/LPStaking.json";
import LPPresale from "../abis/LPPresale.json";
import { constants, Contract, ethers, utils } from "ethers";
import { values } from "../constants/walletConnectChains";
import { getDecimals, getWeth } from "./etherFunctions";

export async function getLaunchpadDetails(launchpad, tokenAddress, type, signer) {
  if (launchpad && tokenAddress) {
    if (type === 0) {
      const presaleContractAddress = await launchpad.getPresaleContractsByTokenAddress(tokenAddress);
      if (presaleContractAddress) {
        const presaleContract = await new Contract(presaleContractAddress[0], LPPresale, signer);
        const presaleFilledValue = await presaleContract.getFilledValue();
        if (presaleFilledValue) {
          return {
            contract: presaleContractAddress[0],
            filledValue: utils.formatEther(presaleFilledValue),
          };
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else if (type === 1) {
      const stakingContractAddress = await launchpad.getStakingContractsByTokenAddress(tokenAddress);
      if (stakingContractAddress) {
        const stakingContract = await new Contract(stakingContractAddress[0], LPStaking, signer);
        const stakingFilledValue = await stakingContract.getFilledValue();
        if (stakingFilledValue) {
          return {
            contract: stakingContractAddress[0],
            filledValue: utils.formatEther(stakingFilledValue),
          };
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export async function buyPresaleOrStake(amount, presale, staking, tokenAddress, type, signer, buyTokenAddress, buydecimals) {
  if (presale && staking && tokenAddress) {
    if (type === 0) {
      const presaleContractAddress = await presale.getPresaleContractsByTokenAddress(tokenAddress);
      if (presaleContractAddress) {
        const presaleContract = await new Contract(presaleContractAddress[0], LPPresale, signer);
        const tx = await presaleContract.buyTokens({ value: ethers?.utils?.parseUnits(amount.toString(), "18") });
        await tx.wait();
        return true;
      } else {
        return false;
      }
    } else if (type === 1) {
      const stakingContractAddress = await staking.getStakingContractsByTokenAddress(tokenAddress);
      if (stakingContractAddress) {
        const stakingContract = await new Contract(stakingContractAddress[0], LPStaking, signer);
        if (buyTokenAddress !== null && buyTokenAddress !== "null" && buyTokenAddress !== constants?.AddressZero) {
          const tx = await stakingContract.buyTokens(ethers?.utils?.parseUnits(amount.toString(), buydecimals));
          await tx.wait();
          return true;
        } else {
          const tx = await stakingContract.buyTokens("0", { value: ethers?.utils?.parseUnits(amount.toString(), buydecimals) });
          await tx.wait();
          return true;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export async function checkForWhitelist(presale, staking, tokenAddress, type, user, signer) {
  if (presale && staking && tokenAddress) {
    if (type === 0) {
      const presaleContractAddress = await presale.getPresaleContractsByTokenAddress(tokenAddress);
      if (presaleContractAddress) {
        const presaleContract = await new Contract(presaleContractAddress[0], LPPresale, signer);
        if (presaleContract) {
          const result = await presaleContract.isWhiteListed(user);
          return result;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else if (type === 1) {
      const stakingContractAddress = await staking.getStakingContractsByTokenAddress(tokenAddress);
      if (stakingContractAddress) {
        const stakingContract = await new Contract(stakingContractAddress[0], LPStaking, signer);
        if (stakingContract) {
          const result = await stakingContract.isWhiteListed(user);
          return result;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export async function whiteAllAddresses(presale, staking, tokenAddress, type, signer, user) {
  try {
    if (presale && staking && tokenAddress) {
      if (type === 0) {
        const presaleContractAddress = await presale.getPresaleContractsByTokenAddress(tokenAddress);
        if (presaleContractAddress) {
          const presaleContract = await new Contract(presaleContractAddress[0], LPPresale, signer);
          if (presaleContract) {
            const tx = await presaleContract.whitelistAddress(user);
            await tx.wait();
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else if (type === 1) {
        const stakingContractAddress = await staking.getStakingContractsByTokenAddress(tokenAddress);
        if (stakingContractAddress) {
          const stakingContract = await new Contract(stakingContractAddress[0], LPStaking, signer);
          if (stakingContract) {
            const tx = await stakingContract.whitelistAddress(user);
            await tx.wait();
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  } catch (err) {
    // console.log("error in whiteAllAddresses : ", err);
  }
}

export async function removeAllWhitelistAddresses(presale, staking, tokenAddress, type, signer, user) {
  try {
    if (presale && staking && tokenAddress) {
      if (type === 0) {
        const presaleContractAddress = await presale.getPresaleContractsByTokenAddress(tokenAddress);
        if (presaleContractAddress) {
          const presaleContract = await new Contract(presaleContractAddress[0], LPPresale, signer);
          if (presaleContract) {
            const tx = await presaleContract.removeWhitelist(user);
            await tx.wait();
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else if (type === 1) {
        const stakingContractAddress = await staking.getStakingContractsByTokenAddress(tokenAddress);
        if (stakingContractAddress) {
          const stakingContract = await new Contract(stakingContractAddress[0], LPStaking, signer);
          if (stakingContract) {
            const tx = await stakingContract.removeWhitelist(user);
            await tx.wait();
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  } catch (err) {
    // console.log("error in whiteAllAddresses : ", err);
  }
}

export async function getWhiteListAddressFromContract(presale, staking, tokenAddress, type, signer) {
  try {
    if (presale && staking && tokenAddress) {
      if (type === 0) {
        const presaleContractAddress = await presale.getPresaleContractsByTokenAddress(tokenAddress);
        if (presaleContractAddress) {
          const presaleContract = await new Contract(presaleContractAddress[0], LPPresale, signer);
          if (presaleContract) {
            const result = await presaleContract.getWhiteListedAddresses();
            return result;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else if (type === 1) {
        const stakingContractAddress = await staking.getStakingContractsByTokenAddress(tokenAddress);
        if (stakingContractAddress) {
          const stakingContract = await new Contract(stakingContractAddress[0], LPStaking, signer);
          if (stakingContract) {
            const result = await stakingContract.getWhiteListedAddresses();
            return result;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  } catch (err) {
    // console.log("error : ", err);
    return false;
  }
}

export async function getStakingAndPresalesBuying(presale, staking, user, type, signer) {
  try {
    if (presale && staking && user) {
      let presalesWithValue = [];
      const presales = await presale.getUserPresaleBuyings(user);
      if (presales?.length) {
        for (let i = 0; i < presales?.length; i++) {
          const presaleContract = await new Contract(presales[i], LPPresale, signer);
          const presaleData = await presaleContract.getData();
          const filledValue = await presaleContract.filledValue();
          let obj = {
            owner: presaleData?.admin,
            endDate: new Date(Number(presaleData?.endDate.toString()) * 1000),
            hardCap: ethers.utils.formatEther(presaleData?.hardCap),
            listingRate: ethers.utils.formatEther(presaleData?.listingRate),
            maxPurchaseAmount: ethers.utils.formatEther(presaleData?.maxPurchaseAmount),
            minPurchaseAmount: ethers.utils.formatEther(presaleData?.minPurchaseAmount),
            presaleRate: ethers.utils.formatEther(presaleData?.presaleRate),
            softCap: ethers.utils.formatEther(presaleData?.softCap),
            startDate: new Date(Number(presaleData?.startDate.toString()) * 1000),
            tokenAddress: presaleData?.tokenAddress,
            filledValue: ethers.utils.formatEther(filledValue),
          };
          presalesWithValue.push(obj);
        }
      }
      let stakingWithValue = [];
      const stakings = await staking.getUserStakeBuyings(user);
      if (stakings?.length) {
        for (let i = 0; i < stakings?.length; i++) {
          const stakingContract = await new Contract(stakings[i], LPStaking, signer);
          const stakingData = await stakingContract.getData();
          const filledValue = await stakingContract.filledValue();
          let obj = {
            owner: stakingData?.admin,
            endDate: new Date(Number(stakingData?.endDate.toString()) * 1000),
            hardCap: stakingData?.hardCap,
            listingRate: ethers.utils.formatEther(stakingData?.listingRate),
            maxPurchaseAmount: stakingData?.maxPurchaseAmount,
            minPurchaseAmount: stakingData?.minPurchaseAmount,
            presaleRate: ethers.utils.formatEther(stakingData?.stakingRate),
            softCap: stakingData?.softCap,
            startDate: new Date(Number(stakingData?.startDate.toString()) * 1000),
            tokenAddress: stakingData?.tokenAddress,
            filledValue: filledValue?.toString(),
          };
          stakingWithValue.push(obj);
        }
      }
      return {
        presales: presalesWithValue,
        stakings: stakingWithValue,
      };
    } else {
      return false;
    }
  } catch (err) {
    // console.log("error : ", err);
    return false;
  }
}

export async function getContributorsList(presale, staking, tokenAddress, type, signer) {
  try {
    if (presale && staking && tokenAddress) {
      if (type === 0) {
        const presaleContractAddress = await presale.getPresaleContractsByTokenAddress(tokenAddress);
        if (presaleContractAddress) {
          const presaleContract = await new Contract(presaleContractAddress[0], LPPresale, signer);
          if (presaleContract) {
            const result = await presaleContract.viewAllContributors();
            return result;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else if (type === 1) {
        const stakingContractAddress = await staking.getStakingContractsByTokenAddress(tokenAddress);
        if (stakingContractAddress) {
          const stakingContract = await new Contract(stakingContractAddress[0], LPStaking, signer);
          if (stakingContract) {
            const result = await stakingContract.viewAllContributors();
            return result;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  } catch (err) {
    // console.log("error : ", err);
    return false;
  }
}
export async function getUserContribution(presale, staking, tokenAddress, user, type, signer, decimals) {
  try {
    if (presale && staking && tokenAddress) {
      if (type === 0) {
        const presaleContractAddress = await presale.getPresaleContractsByTokenAddress(tokenAddress);
        if (presaleContractAddress) {
          const presaleContract = await new Contract(presaleContractAddress[0], LPPresale, signer);
          if (presaleContract) {
            const result = await presaleContract.userContribution(user);
            const baseBal = await presaleContract.balancesInBase(user);
            return { contribution: ethers.utils.formatEther(result), baseBalance: ethers.utils.formatEther(baseBal) };
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else if (type === 1) {
        const stakingContractAddress = await staking.getStakingContractsByTokenAddress(tokenAddress);
        if (stakingContractAddress) {
          const stakingContract = await new Contract(stakingContractAddress[0], LPStaking, signer);
          if (stakingContract) {
            const result = await stakingContract.userContribution(user);
            const baseBal = await stakingContract.balancesInBase(user);
            return { contribution: ethers.utils.formatUnits(result, decimals), baseBalance: ethers.utils.formatUnits(baseBal, decimals) };
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  } catch (err) {
    console.log("error : ", err);
    return false;
  }
}

export async function getLastClaimedFromContract(presale, staking, tokenAddress, user, type, signer) {
  try {
    if (presale && staking && tokenAddress) {
      const token = await getWeth(tokenAddress, signer);
      const decimals = await getDecimals(token);
      if (type === 0) {
        const presaleContractAddress = await presale.getPresaleContractsByTokenAddress(tokenAddress);
        if (presaleContractAddress) {
          const presaleContract = await new Contract(presaleContractAddress[0], LPPresale, signer);
          if (presaleContract) {
            const result = await presaleContract.lastClaimed(user);
            const total = await presaleContract.totalNoOfClaims(user);
            const balance = await presaleContract.balances(user);
            return {
              timestemp: Number(result?.toString()) * 1000,
              total: total?.toString(),
              balance: ethers.utils.formatUnits(balance, decimals),
            };
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else if (type === 1) {
        const stakingContractAddress = await staking.getStakingContractsByTokenAddress(tokenAddress);
        if (stakingContractAddress) {
          const stakingContract = await new Contract(stakingContractAddress[0], LPStaking, signer);
          if (stakingContract) {
            const result = await stakingContract.lastClaimed(user);
            const total = await stakingContract.totalNoOfClaims(user);
            const balance = await stakingContract.balances(user);
            return {
              timestemp: Number(result?.toString()) * 1000,
              total: total?.toString(),
              balance: ethers.utils.formatUnits(balance, decimals),
            };
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  } catch (err) {
    // console.log("error : ", err);
    return false;
  }
}

export async function getVestinInfoFromContract(presale, staking, tokenAddress, type, signer) {
  try {
    if (presale && staking && tokenAddress) {
      if (type === 0) {
        const presaleContractAddress = await presale.getPresaleContractsByTokenAddress(tokenAddress);
        if (presaleContractAddress) {
          const presaleContract = await new Contract(presaleContractAddress[0], LPPresale, signer);
          if (presaleContract) {
            const isenabled = await presaleContract.vestingEnabled();
            const percentage = await presaleContract.vestingPercentage();
            const duration = await presaleContract.cliffDuration();
            return { isenabled, percentage: percentage?.toString(), duration: duration?.toString() };
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else if (type === 1) {
        const stakingContractAddress = await staking.getStakingContractsByTokenAddress(tokenAddress);
        if (stakingContractAddress) {
          const stakingContract = await new Contract(stakingContractAddress[0], LPStaking, signer);
          if (stakingContract) {
            const isenabled = await stakingContract.vestingEnabled();
            const percentage = await stakingContract.vestingPercentage();
            const duration = await stakingContract.cliffDuration();
            return { isenabled, percentage: percentage?.toString(), duration: duration?.toString() };
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  } catch (err) {
    // console.log("error : ", err);
    return false;
  }
}
export async function enableVestingInContract(presale, staking, tokenAddress, type, signer, percentage, duration) {
  try {
    if (presale && staking && tokenAddress) {
      if (type === 0) {
        const presaleContractAddress = await presale.getPresaleContractsByTokenAddress(tokenAddress);
        if (presaleContractAddress) {
          const presaleContract = await new Contract(presaleContractAddress[0], LPPresale, signer);
          if (presaleContract) {
            const tx = await presaleContract?.enableVesting(ethers.utils.parseUnits(percentage, "18"), duration);
            await tx.wait();
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else if (type === 1) {
        const stakingContractAddress = await staking.getStakingContractsByTokenAddress(tokenAddress);
        if (stakingContractAddress) {
          const stakingContract = await new Contract(stakingContractAddress[0], LPStaking, signer);
          if (stakingContract) {
            const tx = await stakingContract?.enableVesting(ethers.utils.parseUnits(percentage, "18"), duration);
            await tx.wait();
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  } catch (err) {
    // console.log("error in enableVestingInContract : ", err);
    return false;
  }
}

export async function disableVestingInContract(presale, staking, tokenAddress, type, signer, percentage, duration) {
  try {
    if (presale && staking && tokenAddress) {
      if (type === 0) {
        const presaleContractAddress = await presale.getPresaleContractsByTokenAddress(tokenAddress);
        if (presaleContractAddress) {
          const presaleContract = await new Contract(presaleContractAddress[0], LPPresale, signer);
          if (presaleContract) {
            const tx = await presaleContract?.disableVesting();
            await tx.wait();
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else if (type === 1) {
        const stakingContractAddress = await staking.getStakingContractsByTokenAddress(tokenAddress);
        if (stakingContractAddress) {
          const stakingContract = await new Contract(stakingContractAddress[0], LPStaking, signer);
          if (stakingContract) {
            const tx = await stakingContract?.disableVesting();
            await tx.wait();
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  } catch (err) {
    // console.log("error : ", err);
    return false;
  }
}
export async function claimTokenInContract(presale, staking, tokenAddress, type, signer) {
  try {
    if (presale && staking && tokenAddress) {
      if (type === 0) {
        const presaleContractAddress = await presale.getPresaleContractsByTokenAddress(tokenAddress);
        if (presaleContractAddress) {
          const presaleContract = await new Contract(presaleContractAddress[0], LPPresale, signer);
          if (presaleContract) {
            const tx = await presaleContract?.claimTokens();
            await tx.wait();
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else if (type === 1) {
        const stakingContractAddress = await staking.getStakingContractsByTokenAddress(tokenAddress);
        if (stakingContractAddress) {
          const stakingContract = await new Contract(stakingContractAddress[0], LPStaking, signer);
          if (stakingContract) {
            const tx = await stakingContract?.claimTokens({ value: "0" });
            await tx.wait();
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  } catch (err) {
    console.log("error in claimTokenInContract : ", err);
    return false;
  }
}

export async function getContractBalances(tokenAddress, contractAddress, signer, provider) {
  try {
    const token = await getWeth(tokenAddress, signer);
    const decimals = await getDecimals(token);
    if (token) {
      const tokenbal = await token.balanceOf(contractAddress);
      const etherbal = await provider?.getBalance(contractAddress);
      return { etherBal: ethers.utils.formatEther(etherbal), tokenBal: ethers.utils.formatUnits(tokenbal, decimals) };
    } else {
      return false;
    }
  } catch (err) {
    // console.log("error : ", err);
    return false;
  }
}

export async function withdrawContractCoins(presale, staking, tokenAddress, type, amount, signer) {
  try {
    if (presale && staking && tokenAddress) {
      if (type === 0) {
        const presaleContractAddress = await presale.getPresaleContractsByTokenAddress(tokenAddress);
        if (presaleContractAddress) {
          const presaleContract = await new Contract(presaleContractAddress[0], LPPresale, signer);
          if (presaleContract) {
            const tx = await presaleContract?.withdrawEther(ethers.utils.parseUnits(amount, "18"));
            await tx.wait();
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else if (type === 1) {
        const stakingContractAddress = await staking.getStakingContractsByTokenAddress(tokenAddress);
        if (stakingContractAddress) {
          const stakingContract = await new Contract(stakingContractAddress[0], LPStaking, signer);
          if (stakingContract) {
            const tx = await stakingContract?.withdrawEther(ethers.utils.parseUnits(amount, "18"));
            await tx.wait();
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  } catch (err) {
    // console.log("error : ", err);
    return false;
  }
}

export async function withdrawContractToken(presale, staking, tokenAddress, type, signer) {
  try {
    if (presale && staking && tokenAddress) {
      const token = await getWeth(tokenAddress, signer);
      // const decimals = await getDecimals(token);
      if (type === 0) {
        const presaleContractAddress = await presale.getPresaleContractsByTokenAddress(tokenAddress);
        if (presaleContractAddress) {
          const presaleContract = await new Contract(presaleContractAddress[0], LPPresale, signer);
          if (presaleContract) {
            const tx = await presaleContract?.withdrawal();
            await tx.wait();
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else if (type === 1) {
        const stakingContractAddress = await staking.getStakingContractsByTokenAddress(tokenAddress);
        if (stakingContractAddress) {
          const stakingContract = await new Contract(stakingContractAddress[0], LPStaking, signer);
          if (stakingContract) {
            const tx = await stakingContract?.withdrawToken();
            await tx.wait();
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  } catch (err) {
    // console.log("error in withdrawContractToken : ", err);
    return false;
  }
}

export async function depositTokenForLiquidityPresale(presale, tokenAddress, type, amount, signer) {
  try {
    if (presale && tokenAddress) {
      const token = await getWeth(tokenAddress, signer);
      const decimals = await getDecimals(token);
      if (type === 0) {
        const presaleContractAddress = await presale.getPresaleContractsByTokenAddress(tokenAddress);
        if (presaleContractAddress) {
          const presaleContract = await new Contract(presaleContractAddress[0], LPPresale, signer);
          if (presaleContract) {
            const tx = await presaleContract?.deposite(ethers.utils.parseUnits(amount?.toString(), decimals));
            await tx.wait();
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  } catch (err) {
    // console.log("error : ", err);
    return false;
  }
}

export async function getTotalTokensDesired(presale, tokenAddress, type, signer) {
  try {
    if (presale && tokenAddress) {
      const token = await getWeth(tokenAddress, signer);
      const decimals = await getDecimals(token);
      if (type === 0) {
        const presaleContractAddress = await presale.getPresaleContractsByTokenAddress(tokenAddress);
        if (presaleContractAddress) {
          const presaleContract = await new Contract(presaleContractAddress[0], LPPresale, signer);
          if (presaleContract) {
            const result = await presaleContract?.totalTokensDesired();
            return Number(ethers.utils.formatUnits(result, decimals));
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  } catch (err) {
    // console.log("error : ", err);
    return false;
  }
}
